<template>
	<Popup name="cigognes"
	text="<h3>Les cigognes</h3><br/><p>L'association Acrola est impliquée dans de nombreux programmes d'ornithologie. Les activités sont menées principalement par des bénévoles et sont soutenues financièrement par de nombreux partenaires, dont Total depuis une quinzaine d’années.</p><br/><p>Acrola développe un programme d’études intitulé &laquo; Sur la route des cigognes &raquo;, basé sur l’identification et la localisation des cigognes. Une plateforme d’accueil est implantée depuis 2009 dans l’enceinte de la raffinerie. Chaque année, 2 à 3 cigogneaux y naissent et sont bagués pour les suivre et connaitre leur historique de vie.</p>"
	video="static/video/cigognes.mp4"
	sticker="static/logo-total.jpg">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/cigognes_gg1kne_c_scale_w_350.jpg 350w,
		img/popup/cigognes_gg1kne_c_scale_w_721.jpg 721w,
		img/popup/cigognes_gg1kne_c_scale_w_889.jpg 889w"
		data-src="img/popup/cigognes_gg1kne_c_scale_w_889.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
	<picture slot="thumbnail__2">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/cigognes2_bypkaa_c_scale_w_350.jpg 350w,
		img/popup/cigognes2_bypkaa_c_scale_w_768.jpg 768w,
		img/popup/cigognes2_bypkaa_c_scale_w_970.jpg 970w"
		data-src="cigognes2_bypkaa_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</Popup>
</template>

<script>
	export default {
		components: {
			Popup: () => import('@/components/Popup.vue'),
		},
	}
</script>
